<template>
  <IntegrationContent>
    <template #title>
      {{ title }}
    </template>
    <SlCheckbox
      v-model="aggregationByDayModel"
      :label="$t('DbInsideImport.Other.OneTranPerDay')"
    />
  </IntegrationContent>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';

export default {
  name: 'ImportSettings',
  components: {
    IntegrationContent
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      configuration: state => state.integrations.settings.configuration
    }),
    aggregationByDayModel: {
      get() {
        return this.configuration.aggregationByDay;
      },
      set(value) {
        this.updateConfiguration({
          aggregationByDay: value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'integrations/settings/updateConfiguration'
    })
  }
};
</script>
