<template>
  <IntegrationContent>
    <template #title>
      {{ title }}
    </template>
    <IntegrationQuery
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQuery')"
    >
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          v-if="isDatabaseRelated"
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </IntegrationQuery>

    <IntegrationContent v-if="isDatabaseRelated">
      <template #title>
        {{ $t('Web.DbImport.TitleToReceiveSetting') }}
      </template>
      <template #subtitle>
        {{ $t('Web.DbImport.TextToReceiveSetting') }}
      </template>

      <IntegrationMatch
        :left-label="$t('Web.DbImport.ToReceiveSlValue')"
        :right-label="$t('Web.DbImport.ToReceiveDbValue')"
      >
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbPurchase') }}
          </div>
          <SlInput
            v-model="purchaseModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbTransfer') }}
          </div>
          <SlInput
            v-model="transferModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
        <div class="integration-match-row">
          <div class="w-150 body-1 grey-80">
            {{ $t('DbImportDialog.Ui.lbWork') }}
          </div>
          <SlInput
            v-model="workModel"
            class="w-240"
            :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
          />
        </div>
      </IntegrationMatch>
    </IntegrationContent>
  </IntegrationContent>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationQuery from '@/components/Integrations/IntegrationQuery.vue';
import IntegrationMatch from '@/components/Integrations/IntegrationMatch.vue';

export default {
  name: 'OrdersToReceive',
  components: {
    IntegrationQuery,
    IntegrationContent,
    IntegrationMatch
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tab: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setIntegrationSettings',
    'isDatabaseRelated'
  ],
  computed: {
    ...mapState({
      transform: state => state.integrations.transform.import,
      configuration: state => state.integrations.settings.configuration
    }),
    tabData() {
      return this.transform[this.tab] || {};
    },
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'query' });
      }
    },
    purchaseModel: {
      get() {
        return this.configuration.purchase;
      },
      set(value) {
        this.updateConfiguration({
          purchase: value
        });
      }
    },
    transferModel: {
      get() {
        return this.configuration.transfer;
      },
      set(value) {
        this.updateConfiguration({
          transfer: value
        });
      }
    },
    workModel: {
      get() {
        return this.configuration.work;
      },
      set(value) {
        this.updateConfiguration({
          work: value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'integrations/settings/updateConfiguration'
    })
  }
};
</script>