<template>
  <div class="integration-content">
    <div
      v-if="$scopedSlots.title || $scopedSlots.subtitle"
      class="integration-content__header"
    >
      <div
        v-if="$scopedSlots.title"
        class="integration-content__title heading-3-sb grey-100"
      >
        <slot name="title" />
      </div>
      <div
        v-if="$scopedSlots.subtitle"
        class="integration-content__subtitle body-1 grey-70"
      >
        <slot name="subtitle" />
      </div>
    </div>
    <div class="integration-content__main">
      <slot />

      <div
        v-if="$scopedSlots.actions"
        class="integration-content__actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationContent'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/integrations/integration-content.scss";
</style>