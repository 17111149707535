<template>
  <div class="integration-match">
    <div class="integration-match-row">
      <div class="body-4-md grey-60 w-150">
        {{ leftLabel }}
      </div>
      <div
        v-if="middleLabel"
        class="body-4-md grey-60 w-240"
      >
        {{ middleLabel }}
      </div>
      <div class="body-4-md grey-60 w-240">
        {{ rightLabel }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IntegrationMatch',
  props: {
    leftLabel: {
      type: String,
      default: ''
    },
    middleLabel: {
      type: String,
      default: ''
    },
    rightLabel: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/integrations/integration-match.scss";
</style>