import { mapActions, mapGetters, mapState } from 'vuex';
import { routeNames } from '@/config/router/router.config';

export const transformShared = {
  inject: ['setIsLoading'],
  provide() {
    return {
      setIntegrationSettings: this.setSettings,
      isDatabaseRelated: this.isDatabaseRelated
    };
  },
  computed: {
    ...mapState({
      tables: state => state.integrations.transform.tables || []
    }),
    ...mapGetters({
      isDatabaseRelated: 'integrations/isDatabaseRelated'
    }),
    tabData() {
      return this.transformState[this.tabModel] ?? {};
    },
    tabStubs() {
      return this.tabData.stubs || null;
    },
    isAggregation() {
      return this.$sl_routeName === routeNames.AGGREGATION;
    }
  },
  watch: {
    tabModel() {
      this.resetTemporaryTablePreview();
    }
  },
  methods: {
    ...mapActions({
      resetTemporaryTablePreview: 'integrations/transform/resetTemporaryTablePreview'
    })
  }
};
