<template>
  <transition name="fade">
    <IntegrationContent
      v-if="isPreviewVisible"
      class="table-preview"
    >
      <template #title>
        {{ previewTitle }}
      </template>
      <template
        v-if="!temporaryTableName"
        #subtitle
      >
        {{ $t('Web.DbImport.TextPreviewQuery') }}
      </template>
      <SlTable
        :id="`${tab}-preview`"
        :headers="previewHeaders"
        :lower-headers="previewSlots"
        :rows="previewRows"
        unique-key="id"
        header-unique-key="key"
        header-name-key="text"
        :max-height="previewTableMaxHeight"
        :loading="isPreviewLoading"
        :value-parser="parseValue"
        num-col
        :inner-no-data="!!previewHeaders.length && !temporaryTableName"
        inner-no-data-editable
      >
        <template
          v-for="(header, headerIndex) in previewHeaders"
          #[`lower-header-${header.key}`]
        >
          <SlTableSelect
            :key="header.key"
            :value="colSlotValue(headerIndex)"
            :options="colSlotOptions(headerIndex)"
            label="name"
            track-by="colMeaning"
            allow-empty
            return-object
            searchable
            clearable
            @input="(value) => handleUpdateColSlot(value, headerIndex)"
          />
        </template>
        <template #before-outer>
          <transition name="fade">
            <div
              v-if="previewLimitVisible"
              class="rows-limit-message body-1 grey-60"
            >
              {{ $t('Web.DbImport.TablePreviewLimit', { 1: previewRowsLimit }) }}
            </div>
          </transition>
        </template>
        <template #no-data>
          <SlNoData>
            <template #text>
              {{ noDataWarning }}
            </template>
          </SlNoData>
        </template>
      </SlTable>
    </IntegrationContent>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import { getColumnSlotOptions } from '@/helpers/connection';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'TablePreview',
  components: {
    IntegrationContent
  },
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      previewRowsLimit: 100,
      previewTableMaxHeight: 550
    };
  },
  computed: {
    ...mapState({
      transformState: state => state.integrations.transform,
      temporaryTableName: state => state.integrations.transform.temporary_table_name
    }),
    temporaryPreview() {
      return this.transformState.temporary_preview || {};
    },
    tabData() {
      return this.transformState.import[this.tab] || {};
    },
    preview() {
      if (this.temporaryTableName) {
        return this.temporaryPreview;
      }

      return this.tabData.importPreview;
    },
    previewHeaders() {
      return toArray(this.preview?.headers);
    },
    previewRows() {
      return toArray(this.preview?.rows);
    },
    previewSlots() {
      // pass same headers again to sync col keys
      return this.tabData?.availableSlots && !this.temporaryTableName
        ? this.previewHeaders
        : null;
    },
    previewTitle() {
      return this.temporaryTableName
        ? this.$t('Web.DbImport.TitlePreviewTable', { 1: this.temporaryTableName })
        : this.$t('Web.DbImport.TitlePreviewQuery');
    },
    previewLimitVisible() {
      return this.previewRows?.length >= this.previewRowsLimit;
    },
    noDataWarning() {
      const title = this.$t('Web.DbImport.TextQueryNoData');
      const text = this.temporaryTableName
        ? this.$t('Web.DbImport.TextNoDataTablePreview', { 1: this.temporaryTableName })
        : this.$t('Web.DbImport.TextNoDataTableQuery');

      return `${title} \n${text}`;
    },
    isPreviewLoading() {
      return this.preview?.isLoading;
    },
    isPreviewLoaded() {
      return this.preview?.isLoaded;
    },
    isPreviewVisible() {
      return this.isPreviewLoading || this.isPreviewLoaded;
    }
  },
  methods: {
    ...mapActions({
      setColSlot: 'integrations/transform/import/setColSlot'
    }),
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    colSlotOptions(colIndex) {
      return getColumnSlotOptions(this.tabData, colIndex);
    },
    colSlotValue(index) {
      return this.tabData.matchedSlots[index];
    },
    handleUpdateColSlot(slotValue, colIndex) {
      this.setColSlot({
        tab: this.tab,
        value: slotValue,
        index: colIndex
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_mixins.scss";

.table-preview {
  margin-top: 20px;

  .integration-content__header {
    margin-bottom: 16px;

    .integration-content__title {
      @include heading-4-sb;
    }
  }
}
</style>
