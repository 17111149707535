<template>
  <IntegrationContent>
    <template #title>
      {{ title }}
    </template>
    <IntegrationQuery
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQuery')"
    >
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          v-if="isDatabaseRelated"
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </IntegrationQuery>
  </IntegrationContent>
</template>

<script>
import { mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationQuery from '@/components/Integrations/IntegrationQuery.vue';

export default {
  name: 'BaseTab',
  components: {
    IntegrationQuery,
    IntegrationContent
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tab: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setIntegrationSettings',
    'isDatabaseRelated'
  ],
  computed: {
    ...mapState({
      transform: state => state.integrations.transform.import
    }),
    tabData() {
      return this.transform[this.tab] || {};
    },
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'query' });
      }
    }
  }
};
</script>