<template>
  <IntegrationContent>
    <template #title>
      {{ title }}
    </template>
    <IntegrationQuery
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQueryOrder')"
      :placeholder="`${$t('Web.DbImport.PlaceholderQueryOrderSelect')}\n${$t('Web.DbImport.PlaceholderQueryOrderBy')}`"
      :stubs="stubs"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="startDateTransform"
        :rules="startDateRules"
      >
        <SlDatePicker
          v-model="startDateModel"
          :label="$t('DbInsideImport.Ui.lbStartDate')"
          class="w-240"
          :is-invalid="invalid"
        />
      </SlValidate>
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          v-if="isDatabaseRelated"
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </IntegrationQuery>
  </IntegrationContent>
</template>

<script>
import { mapState } from 'vuex';
import IntegrationContent from '@/components/Integrations/IntegrationContent.vue';
import IntegrationQuery from '@/components/Integrations/IntegrationQuery.vue';
import { tabKeys } from '@/config/integrations/transform.config';

export default {
  name: 'Transactions',
  components: {
    IntegrationQuery,
    IntegrationContent
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tab: {
      type: String,
      default: ''
    },
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setIntegrationSettings',
    'isDatabaseRelated'
  ],
  computed: {
    ...mapState({
      settings: state => state.integrations.transform.import[tabKeys.SETTINGS],
      transform: state => state.integrations.transform.import
    }),
    tabData() {
      return this.transform[this.tab] || {};
    },
    startDateRules() {
      return {
        'sl_date_between': true,
        'required': this.stubs?.some(stub => this.queryModel.includes(stub.value))
      };
    },
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'query' });
      }
    },
    startDateModel: {
      get() {
        return this.settings.startDate;
      },
      set(value) {
        this.setIntegrationSettings({ value, key: 'startDate', tab: tabKeys.SETTINGS });
      }
    }
  }
};
</script>
