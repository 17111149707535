var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tables-info"},[_c('div',{staticClass:"tables-info__title body-4-md grey-60"},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.LabelDbTables'))+" "),_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.DbImport.TooltipDbTables'))),expression:"getTooltip($t('Web.DbImport.TooltipDbTables'))"}]}),_c('SlButton',{staticClass:"tables-info__title--refresh",attrs:{"size":"xs","variant":"secondary","color":"grey","icon":""},on:{"click":_vm.fetchTablesList}},[_c('icon',{staticClass:"fill-off size-16",class:{
          'spin-reverse': _vm.isTablesRefreshing
        },attrs:{"data":require("@icons/refresh.svg")}})],1)],1),(_vm.isIntegration)?_c('TablesInfoList',{attrs:{"tables":_vm.tables[0].tables}}):_vm._l((_vm.tables),function(tablesItem){return _c('SlAccordion',{key:tablesItem.connector.id,scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var isOpen = ref.isOpen;
        var toggle = ref.toggle;
return [_c('div',{staticClass:"tables-accordion__trigger body-4-md grey-80",attrs:{"title":tablesItem.connector.name},on:{"click":toggle}},[_c('div',{staticClass:"tables-accordion__trigger-text ellipsis"},[_vm._v(" "+_vm._s(tablesItem.connector.name)+" ")]),_c('icon',{staticClass:"fill-off size-20",class:{
              'rotate-180': isOpen
            },attrs:{"data":require("@icons/chevron_down.svg")}})],1)]}}],null,true)},[_c('TablesInfoList',{attrs:{"tables":tablesItem.tables}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }