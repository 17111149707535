import { mapState } from 'vuex';
import { integrationModuleTypes, predefinedIntegrations } from '@/config/integrations';

export const transformValidation = {
  computed: {
    ...mapState({
      $sl_activeIntegration: state => state.integrations.active_integration || {}
    }),
    $sl_filledTabs() {
      return this.renderedTabs.filter(({ value }) => this.transformState[value].query);
    },
    $sl_requiredTabs() {
      const tabs = this.$sl_isExport ? this.exportTabs : this.importTabs;

      return tabs.filter(({ required }) => required);
    },
    $sl_requiredSettingsTabs() {
      return this.settingTabs.filter(({ required }) => required);
    },
    $sl_isExport() {
      return this.$sl_activeIntegration.module === integrationModuleTypes.EXPORT;
    }
  },
  methods: {
    $sl_validateActiveTab() {
      const observer = this.$refs[`${this.tabModel}-observer`][0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    },
    $sl_checkUnfilledTab(tabs) {
      return tabs.find(tab => !(this.transformState[tab.value].query));
    },
    async $sl_checkUnmatchedSlot(tabs) {
      if (this.$sl_isExport) {
        return false;
      }

      if (!predefinedIntegrations.includes(this.$sl_activeIntegration.type)) {
        const promises = tabs.reduce((acc, { value: tabKey }) => {
          const tabData = this.transformState[tabKey];

          if (!tabData.importPreview.isLoaded) {
            acc.push(this.fetchTablePreview({ tab: tabKey }));
          }

          return acc;
        }, []);

        if (promises.length) {
          await Promise.allSettled(promises);

          return this.$sl_checkUnmatchedSlot(tabs);
        }
      }

      let result = null;

      tabs.find(({ value: tabKey }) => {
        const tabData = this.transformState[tabKey];
        const matchedSlots = Object.values(tabData.matchedSlots);
        const unmatchedSlots = tabData.availableSlots.reduce((acc, slot) => {
          if (!slot.isRequired) {
            return acc;
          }

          if (!matchedSlots.includes(slot.colMeaning)) {
            acc.push(slot.name);
          }

          return acc;
        }, []);

        if (unmatchedSlots.length) {
          result = {
            tab: tabKey,
            slots: unmatchedSlots
          };

          return true;
        }

        return false;
      });

      return result;
    },
    async $sl_validateNoRequiredTabs() {
      if (!this.$sl_filledTabs.length) {
        this.$notify({
          type: 'error',
          text: this.$sl_isExport
            ? this.$t('Web.Integrations.Errors.CompleteReport')
            : this.$t('Web.Integrations.Errors.CompleteTable'),
          duration: -1
        });

        return false;
      }

      const unmatchedSlots = await this.$sl_checkUnmatchedSlot(this.$sl_filledTabs);

      if (!unmatchedSlots) {
        return true;
      }

      this.tabModel = unmatchedSlots.tab;

      this.$notify({
        type: 'error',
        text: this.$t('Web.Integrations.Errors.SelectColumn', { 1: unmatchedSlots.slots.join(', ') }),
        duration: -1
      });

      return false;
    },
    async $sl_validateRequiredSettingsTab() {
      const shouldValidate = this.$sl_requiredSettingsTabs.some(({ value }) => value === this.tabModel);

      if (!shouldValidate) {
        return true;
      }

      return this.$sl_validateActiveTab();
    },
    async $sl_validateRequiredTabs() {
      const unfilledTab = this.$sl_checkUnfilledTab(this.$sl_requiredTabs);
      const unmatchedSlots = await this.$sl_checkUnmatchedSlot(this.$sl_filledTabs);

      if (!unfilledTab && !unmatchedSlots) {
        return true;
      }

      if (unfilledTab) {
        this.tabModel = unfilledTab.value;
        this.$nextTick(this.validateTab);

        this.$notify({
          type: 'error',
          text: this.$t('Web.DbImport.NotifyFillQueryWarningText', { 1: unfilledTab.label }),
          duration: -1
        });

        return false;
      }

      if (unmatchedSlots) {
        this.tabModel = unmatchedSlots.tab;

        this.$notify({
          type: 'error',
          text: this.$t('Web.Spreadsheet.NotifyMatchColsWarningText', { 1: unmatchedSlots.slots.join(', ') }),
          duration: -1
        });

        return false;
      }
    },
    async $sl_validateFilledTabs() {
      const isSettingsValid = await this.$sl_validateRequiredSettingsTab();

      if (!isSettingsValid) {
        return false;
      }

      if (!this.$sl_requiredTabs.length) {
        return this.$sl_validateNoRequiredTabs();
      }

      return this.$sl_validateRequiredTabs();
    }
  }
};
