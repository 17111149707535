<template>
  <div class="tables-info">
    <div class="tables-info__title body-4-md grey-60">
      {{ $t('Web.DbImport.LabelDbTables') }}
      <SlInfoButton v-tooltip="getTooltip($t('Web.DbImport.TooltipDbTables'))" />
      <SlButton
        size="xs"
        variant="secondary"
        color="grey"
        class="tables-info__title--refresh"
        icon
        @click="fetchTablesList"
      >
        <icon
          data="@icons/refresh.svg"
          class="fill-off size-16"
          :class="{
            'spin-reverse': isTablesRefreshing
          }"
        />
      </SlButton>
    </div>
    <TablesInfoList
      v-if="isIntegration"
      :tables="tables[0].tables"
    />
    <template v-else>
      <SlAccordion
        v-for="tablesItem in tables"
        :key="tablesItem.connector.id"
      >
        <template #trigger="{ isOpen, toggle }">
          <div
            class="tables-accordion__trigger body-4-md grey-80"
            :title="tablesItem.connector.name"
            @click="toggle"
          >
            <div class="tables-accordion__trigger-text ellipsis">
              {{ tablesItem.connector.name }}
            </div>
            <icon
              data="@icons/chevron_down.svg"
              class="fill-off size-20"
              :class="{
                'rotate-180': isOpen
              }"
            />
          </div>
        </template>

        <TablesInfoList :tables="tablesItem.tables" />
      </SlAccordion>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TablesInfoList from '@/components/Integrations/Modules/Transform/TablesInfoList.vue';
import { getTooltip } from '@/helpers/shared/tooltip';
import { copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  name: 'TablesInfo',
  components: {
    TablesInfoList
  },
  props: {
    tables: {
      type: Array,
      default: () => ([])
    },
    isIntegration: Boolean
  },
  data() {
    return {
      isTablesRefreshing: false,
      tablesListBuffer: 200,
      tablesListItemHeight: 25
    };
  },
  methods: {
    ...mapActions({
      fetchTables: 'integrations/transform/fetchTables'
    }),
    getTooltip,
    copyToClipboard,
    async fetchTablesList() {
      try {
        this.isTablesRefreshing = true;

        await this.fetchTables();
      } finally {
        this.isTablesRefreshing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/integrations/transform/tables-info.scss';
</style>