<template>
  <RecycleScroller
    id="database-tables-list"
    class="tables-list"
    :items="tables"
    :buffer="tablesListBuffer"
    :item-size="tablesListItemHeight"
  >
    <template v-slot="{ item }">
      <li class="tables-list__item">
        <div
          :title="item"
          class="tables-list__item-text body-1 grey-70"
          draggable="true"
          @dragstart="handleTableDragstart($event, item)"
        >
          {{ item }}
        </div>
        <div class="tables-list__item-icons">
          <SlButton
            :title="$t('Web.DbImport.TooltipCopy')"
            size="xs"
            variant="text"
            color="grey"
            icon
            @click="copyToClipboard(item)"
          >
            <icon
              data="@icons/copy.svg"
              class="fill-off size-16"
            />
          </SlButton>
          <SlButton
            :title="$t('Web.DbImport.TooltipPreview')"
            size="xs"
            variant="text"
            color="grey"
            icon
            @click="handlePreviewTable(item)"
          >
            <icon
              data="@icons/eye.svg"
              class="fill-off size-16"
            />
          </SlButton>
        </div>
      </li>
    </template>
  </RecycleScroller>
</template>

<script>
import { mapActions } from 'vuex';
import { getTooltip } from '@/helpers/shared/tooltip';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { formatTableName } from '@/helpers/sql';

export default {
  name: 'TablesInfo',
  props: {
    tables: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      tablesListBuffer: 200,
      tablesListItemHeight: 25
    };
  },
  methods: {
    ...mapActions({
      fetchTablePreview: 'integrations/transform/fetchTablePreview'
    }),
    getTooltip,
    copyToClipboard,
    handleTableDragstart(e, table) {
      e.dataTransfer.setData('text/plain', `${formatTableName(table)}`);
    },
    handlePreviewTable(table) {
      this.fetchTablePreview({
        table
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/integrations/transform/tables-info-list.scss';
</style>